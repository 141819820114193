import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// import * as Style from '../ModalDesktop/components/ProductsModalDesktop.style';
import * as Style from './CategoryNavProductsModalDesktop.style';
import { ProductLinks } from '..';
import { Link } from 'gatsby';
import { PAGE_URLS } from 'consts/pageUrls';

export const CategoryNavProductsModalDesktop = () => (
  <React.Fragment>
    <Style.CategoryNavLinksContainer>
      <ProductLinks />
    </Style.CategoryNavLinksContainer>
    <Style.CategoryNavImageContainer>
      <Link to={PAGE_URLS.shop.bestSellers}>
        <StaticImage
          src="../CategoryNav/images/bestSellersDesktop.png"
          alt="Link to shop.curology.com"
          width={300}
        />
      </Link>
    </Style.CategoryNavImageContainer>
  </React.Fragment>
);
