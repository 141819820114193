import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import {
  ANIMATION,
  SPACER,
  MEDIA_QUERIES,
  Z_SCALE,
} from 'radiance-ui/lib/constants';
import { Wordmark } from 'radiance-ui/lib/icons';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { LOCAL_BOX_SHADOWS, LOCAL_COLORS } from 'styles/constants';

import { NavLink } from './components';
import { separatorGrey } from './components/CategoryNav/components/ProductDrawers.style';

const breakpointSize = 'xlUp';

export const HeaderContainer = styled.div`
  position: fixed;
  position: sticky;
  top: 0;
  z-index: ${Z_SCALE.e2000};
`;

export const headerStyles =
  (showTransparent: boolean, hasScrolled: boolean) => (theme: ThemeType) =>
    css`
      position: fixed;
      position: sticky;
      top: 0;
      z-index: ${Z_SCALE.e2000 + 1};
      width: 100%;
      box-shadow: ${hasScrolled && LOCAL_BOX_SHADOWS.nav};
      background-color: ${showTransparent ? 'transparent' : theme.COLORS.white};
      transition: background-color 250ms;
    `;

export const navStyles = (isTransparent: boolean) => css`
  align-items: center;
  border-bottom: ${`1px solid ${separatorGrey}`};
  display: flex;
  gap: ${SPACER.small};
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${isTransparent && `calc(var(--nav-height-mobile) * -1)`};
  min-height: var(--nav-height-mobile);
  padding: 0 ${SPACER.small};
  position: relative;

  ${MEDIA_QUERIES.smUp} {
    padding: 0 ${SPACER.large};
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-top: ${isTransparent && `calc(var(--nav-height-desktop) * -1)`};
    min-height: var(--nav-height-desktop);
    padding: 0 40px;
  }
`;

export const LogoContainer = styled.div`
  z-index: ${Z_SCALE.modal + 1};

  button {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const logoStyles = css`
  display: block;
  width: 40px;
  height: 40px;

  ${MEDIA_QUERIES.smUp} {
    width: 34px;
    height: 34px;
  }
`;

export const watermarkStyles = css`
  display: none;

  ${MEDIA_QUERIES.smUp} {
    display: block;
  }
`;

export const mobileNavElement =
  (showTransparent: boolean) => (theme: ThemeType) =>
    css`
      ${mobileOnly('block', breakpointSize)};
      height: var(--nav-height-mobile);
      background: ${showTransparent ? 'transparent' : theme.COLORS.white};
      z-index: ${Z_SCALE.modal + 1};
    `;

export const Spacer = styled.div`
  flex: 1;
`;

export const FreeTrialText = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.button(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.COLORS.primaryTint1};
  }
`;

export const FreeTrialWrapper = styled(NavLink)`
  ${mobileOnly('block', breakpointSize)};
  border: 1px solid ${({ theme }) => theme.COLORS.primary};
  padding: ${SPACER.small} ${SPACER.medium};
  cursor: pointer;
  border-radius: ${SPACER.xsmall};
  margin-left: auto;
  z-index: ${Z_SCALE.modal + 1};
`;

export const WordmarkCurology = styled(Wordmark)`
  pointer-events: none;
  vertical-align: middle;
  height: 21px;
  width: 94px;
  margin-top: ${SPACER.xsmall};

  ${MEDIA_QUERIES.xlUp} {
    height: 28px;
    width: 120px;
  }
`;

export const iconStyles = css`
  margin: 0 ${SPACER.small};
`;

export const skipToContentLink = css`
  position: absolute;
  transform: translateY(-200%);
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  color: ${LOCAL_COLORS.textInverse} !important;
  font-weight: bold;
  padding: ${SPACER.small};
  z-index: ${Z_SCALE.modal + 2};
  top: ${SPACER.small};
  margin-left: ${SPACER.small};

  :focus {
    transform: translateY(0%);
    outline: ${SPACER.xsmall} auto ${LOCAL_COLORS.surfaceDarkBold};
  }
`;

export const buttonStyles = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.button(theme)};
  ${mobileOnly('block', breakpointSize)};
  font-size: 1rem;
  line-height: 1.5;
  z-index: ${Z_SCALE.modal + 1};
  border-radius: ${theme.BORDER_RADIUS.large};
  padding: ${SPACER.small} ${SPACER.medium};
  color: ${theme.COLORS.white};
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  text-transform: inherit;
  letter-spacing: 0;
  margin-right: ${SPACER.large};
  transition: opacity ${ANIMATION.defaultTiming};

  &:hover {
    background-color: ${LOCAL_COLORS.surfaceBrandBolder};
  }
`;
