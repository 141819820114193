import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { Transition } from 'react-transition-group';
import { Typography } from '@curology/ui-components-web/react';

import APP_URLS from 'consts/appUrls';
import { CTA_FIND_YOUR_TREATMENT } from 'consts/copy';
import { PAGE_URLS } from 'consts/pageUrls';
import { MODULES } from 'consts/modules';
import { SlideDownModal } from 'components/global/modal';
import { trackEvent, trackSignupCTAClick } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { ServicesModalDesktop } from './ModalDesktop/components';
import { NavLink } from './NavLink';

import {
  PrimaryLinkContainer,
  ModalContainer,
  PageLinksContainer,
  AppLinksContainer,
  internalLinkStyles,
  StyledSignupLink,
} from './ModalMobile.style';
import { ProductLinks, Skincare101Links, NestableAccordion } from '.';
import { CategoryNavImageContainer } from './CategoryNav/CategoryNavProductsModalDesktop.style';
import { StaticImage } from 'gatsby-plugin-image';

export const ModalMobile = ({
  isModalOpen,
  setIsModalOpen,
  bannerHeight,
  ctaTextOverride,
  isHidingProductCtas = false,
  isServiceStepOpen = false,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  bannerHeight: number;
  ctaTextOverride?: string;
  isHidingProductCtas?: boolean;
  isServiceStepOpen?: boolean;
}) => {
  const [isProductsOpen, setIsProductsOpen] = useState(true);
  const [isSkincare101Open, setIsSkincare101Open] = useState(false);

  const trackLoginClick = () => {
    trackEvent(EVENTS.loginClicked, {
      path: window.location.pathname,
      module: MODULES.navigation,
    });
  };

  if (typeof window !== 'undefined') {
    const getHtmlNode = () => document.querySelector('html') ?? document.body;
    const htmlNode = useRef<HTMLElement>(getHtmlNode());

    useEffect(() => {
      htmlNode.current = getHtmlNode();
      if (isModalOpen) {
        htmlNode.current.classList.add('no-scroll');
      } else {
        htmlNode.current.classList.remove('no-scroll');
      }
    }, [isModalOpen]);
  }

  return (
    <Transition in={isModalOpen} timeout={250}>
      {(transitionState) => (
        <SlideDownModal
          isOpen={isModalOpen}
          distanceFromTop={`${61 + bannerHeight}px`}
          isFullScreen
        >
          {!isServiceStepOpen && (
            <ModalContainer transitionState={transitionState}>
              <PageLinksContainer>
                {!isHidingProductCtas && (
                  <PrimaryLinkContainer
                    hasAccordion
                    isAccordionOpen={isProductsOpen}
                  >
                    <NestableAccordion
                      title={<Typography weight="bold">Shop</Typography>}
                      isOpen={isProductsOpen}
                      onClick={() => {
                        setIsProductsOpen(!isProductsOpen);
                        setIsSkincare101Open(false);
                      }}
                    >
                      <ProductLinks
                        isOpen={isProductsOpen}
                        setIsModalOpen={setIsModalOpen}
                        isMobile
                      />
                      <CategoryNavImageContainer>
                        <Link to={PAGE_URLS.shop.bestSellers}>
                          <StaticImage
                            src="./CategoryNav/images/bestSellersMobile.png"
                            alt="Link to best sellers"
                            width={343}
                          />
                        </Link>
                      </CategoryNavImageContainer>
                    </NestableAccordion>
                  </PrimaryLinkContainer>
                )}
                <PrimaryLinkContainer onClick={() => setIsModalOpen(false)}>
                  <Link to={PAGE_URLS.whyCurology} css={internalLinkStyles}>
                    <Typography weight="bold">Why Curology</Typography>
                  </Link>
                </PrimaryLinkContainer>
                <PrimaryLinkContainer onClick={() => setIsModalOpen(false)}>
                  <Link to={PAGE_URLS.reviews} css={internalLinkStyles}>
                    <Typography weight="bold">Reviews</Typography>
                  </Link>
                </PrimaryLinkContainer>
                <PrimaryLinkContainer hasAccordion>
                  <NestableAccordion
                    title={<Typography weight="bold">Skincare 101</Typography>}
                    isOpen={isSkincare101Open}
                    onClick={() => {
                      setIsSkincare101Open(!isSkincare101Open);
                      setIsProductsOpen(false);
                    }}
                  >
                    <Skincare101Links
                      isOpen={isSkincare101Open}
                      setIsModalOpen={setIsModalOpen}
                      isMobile
                    />
                  </NestableAccordion>
                </PrimaryLinkContainer>
              </PageLinksContainer>
              <AppLinksContainer>
                <NavLink
                  isExternal
                  href={APP_URLS.login}
                  trackClick={trackLoginClick}
                  useSqueeze
                >
                  Log In
                </NavLink>
                <StyledSignupLink
                  isExternal
                  // TODO [GE-3580]: Temporarily disables the "Find your treatment" modal in favor of just being directed to the skin SUF on click. Keeping the copy "Find your treatment" for the CTA though.
                  href={APP_URLS.signUpSkin}
                  // href={APP_URLS.findYourTreatment}
                  trackClick={() => trackSignupCTAClick(MODULES.navigation)}
                >
                  <Typography size="subhead">
                    {ctaTextOverride || CTA_FIND_YOUR_TREATMENT}
                  </Typography>
                </StyledSignupLink>
              </AppLinksContainer>
            </ModalContainer>
          )}
          {isServiceStepOpen && (
            <ModalContainer transitionState={transitionState}>
              <ServicesModalDesktop module={'nav'} />
            </ModalContainer>
          )}
        </SlideDownModal>
      )}
    </Transition>
  );
};
